import {
  booleanToString,
  getNameToDisplayFromCognito,
  useExpenseForm,
  useGetExpenseCategories,
  useGetFirmUsers,
  useGetUTBMSCodes,
} from '@colosseum/data';
import { DropDownMenuItem, ExpenseType, caseContactConnectionOptions } from '@gladiate/types';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import CaseContactConnectionLinker from '../CaseContactConnectionLinker/CaseContactConnectionLinker';
import ClientSelectDropdown from '../ClientSelectDropdown/ClientSelectDropdown';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import CheckboxFormInput from '../forms/CheckboxFormInput/CheckboxFormInput';
import CurrencyInput from '../forms/CurrencyInput/CurrencyInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface ExpenseFormProps {
  caseId: string;
  expense: ExpenseType;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
  typing: boolean;
}

export function ExpenseForm(props: ExpenseFormProps) {
  const { caseId, expense } = props;

  const { form, handleUpdateExpense } = useExpenseForm({
    expense,
    setShowModal: props.setShowModal,
    setTyping: props.setTyping,
  });

  const { data: expenseCategories } = useGetExpenseCategories();
  const { data: UTBMSCodesData, isLoading: isUTBMSCodesLoading } = useGetUTBMSCodes();

  const firmUsersQuery = useGetFirmUsers();

  const firmUsersData = firmUsersQuery.data?.data;

  const firmUsers =
    firmUsersData?.map((user) => {
      const displayName = getNameToDisplayFromCognito(user) ?? '-';

      return {
        displayName,
        value: user?.Username ?? '',
      };
    }) ?? [];

  const categories = expenseCategories?.data?.reduce((acc, category) => {
    if (category?.title) {
      return { ...acc, [category?.title]: category.caseExpenseCategoryId };
    } else {
      return acc;
    }
  }, {});

  const UTBMSCodes = UTBMSCodesData?.data
    ?.sort((a, b) => {
      //sort where industryStandard of '0' is at the top
      if (a.industryStandard === '0') {
        return -1;
      } else if (b.industryStandard === '0') {
        return 1;
      } else {
        return 0;
      }
    })
    .reduce((acc, code) => {
      if (code?.code) {
        return {
          ...acc,
          [`${code.code} ${code.name}`]: code.caseExpenseUtbmsCodeId,
        };
      } else {
        return acc;
      }
    }, {});

  const formatTotalCost = () => {
    const total = (props?.expense?.unitCount ?? 1) * (props?.expense?.unitPrice ?? 0);
    return total
      ? total.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 0;
  };

  function assigneeSelected(dropDownMenuItem: DropDownMenuItem, expense?: ExpenseType) {
    // if dropDownMenuItem.value is in expense.incurredBy, dont add it
    if (expense?.incurredBy?.includes(dropDownMenuItem.value)) {
      return;
    }

    const newIncurredBy = expense?.incurredBy
      ? [...expense.incurredBy, dropDownMenuItem.value]
      : [dropDownMenuItem.value];
    form.setValue('incurredBy', newIncurredBy, {
      shouldDirty: true,
    });
    handleUpdateExpense('incurredBy', newIncurredBy);
  }

  function removeAssignee(userId: string, expense?: ExpenseType) {
    const newIncurredBy = expense?.incurredBy?.filter((attendee) => attendee !== userId);
    form.setValue('incurredBy', newIncurredBy, {
      shouldDirty: true,
    });
    handleUpdateExpense('incurredBy', newIncurredBy);
  }

  return (
    <Form {...form}>
      <form>
        <ClientSelectDropdown
          caseId={caseId}
          form={form}
          caseItemType="caseExpense"
          caseItemId={expense?.caseExpenseId}
        />
        <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
          <SelectFormInput
            title="Category"
            {...form.register(`categoryId`)}
            listItems={categories ?? []}
            listItemsIsObject
            value={props.expense?.categoryId}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('categoryId', target.value);
            }}
            placeholderText="Select Category"
          />
          <SelectFormInput
            valueClassNames="text-ellipsis overflow-hidden whitespace-nowrap max-w-[180px] "
            contentClassNames="max-w-xs"
            title="UTBMS Code"
            {...form.register(`utbmsCodeId`)}
            listItems={UTBMSCodes ?? []}
            value={props.expense?.utbmsCodeId}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('utbmsCodeId', target.value);
            }}
            placeholderText="Select UTBMS Code"
            listItemsIsObject
          />
          <CalendarFormInput
            {...form.register(`dateIncurred`)}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('dateIncurred', dayjs(target.value).format('YYYY-MM-DD'));
            }}
            title="Date Incurred"
            resourceTypeObj={{
              type: 'expense',
              id: props.expense?.caseExpenseId,
            }}
          />
          <div className="col-span-2 mt-5">
            <h3 className="text-sm font-medium text-gray-900">Incurred By</h3>
            <div className="">
              <div className="flex flex-wrap items-center ">
                {props.expense?.incurredBy &&
                  props.expense?.incurredBy.map((person) => (
                    <div
                      key={person}
                      className="flex mt-3  px-2 py-1.5 text-xs mr-3 font-semibold text-atlantic-blue bg-light-blue rounded-full hover:opacity-75"
                    >
                      {
                        firmUsers?.find((firmUser: DropDownMenuItem) => firmUser.value === person)
                          ?.displayName
                      }
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeAssignee(person, props.expense);
                        }}
                      >
                        <XMarkIcon className="w-4 h-4 ml-2" />
                      </button>
                    </div>
                  ))}
                <div className="w-56">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center flex-shrink-0 mt-3 text-gray-400 bg-white border-2 border-gray-200 border-dashed rounded-full w-7 h-7 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2"
                  >
                    <DropdownMenu
                      menuItems={firmUsers}
                      displayDownArrow={false}
                      handleSelection={(dropDownMenuItem: DropDownMenuItem) => {
                        assigneeSelected(dropDownMenuItem, props.expense);
                      }}
                    >
                      <div className="-mb-1">
                        <span className="sr-only">Add team member</span>
                        <PlusIcon className="w-5 h-5" aria-hidden="true" />
                      </div>
                    </DropdownMenu>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <CaseContactConnectionLinker
              caseId={caseId}
              title="Vendor"
              roleOnCase={caseContactConnectionOptions.vendor}
              itemType="caseExpense"
              itemId={props.expense?.caseExpenseId}
            />
          </div>
          <TextFormInput
            {...form.register(`unitCount`, {
              valueAsNumber: true,
            })}
            handleOnBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('unitCount', Number(target.value));
            }}
            title="Unit Count"
            type="number"
          />
          <TextFormInput
            {...form.register(`unitName`)}
            handleOnBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('unitName', target.value);
            }}
            title="Unit Name"
          />

          <CurrencyInput
            {...form.register('unitPrice', {
              valueAsNumber: true,
            })}
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;

              handleUpdateExpense('unitPrice', target.value);
            }}
            title="Unit Price"
          />
          <div className="flex items-center">Total: ${formatTotalCost()}</div>
          <CheckboxFormInput
            {...form.register('paymentCompleted')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('paymentCompleted', booleanToString(target.value));
            }}
            title="Payment Completed?"
          />

          <CheckboxFormInput
            {...form.register('clientBillable')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdateExpense('clientBillable', booleanToString(target.value));
            }}
            title="Client Billable?"
          />

          <div className="col-span-2">
            <TextFormInput
              {...form.register(`description`)}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateExpense('description', target.value);
              }}
              title="Description"
              type="textarea"
            />
          </div>
        </div>
      </form>
    </Form>
  );
}

export default ExpenseForm;
