import { bitBToB, displayContactName, formatPrice, prettifyDateString } from '@colosseum/data';
import {
  dateRangeFilterFn,
  renderCell,
  renderCellWithChips,
  renderCheckboxCell,
  renderHeader,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { ContactType, ExpenseWithCategoryType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export interface ExpenseDataForTableType extends ExpenseWithCategoryType {
  contact?: ContactType;
  vendor: string;
  incurredByDisplayNames?: string[];
}

export const expenseColumns: ColumnDef<ExpenseDataForTableType>[] = [
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    filterFn: dateRangeFilterFn<ExpenseDataForTableType>,
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.category?.title,
    id: 'Category',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      row?.UTBMSCode?.code ? `${row.UTBMSCode.code} ${row.UTBMSCode.name}` : '-',
    id: 'UTBMS Code',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },

  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Client',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateIncurred),
    id: 'Date Incurred',
    sortUndefined: 1,
    filterFn: dateRangeFilterFn<ExpenseDataForTableType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.vendor,
    id: 'Vendor',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.unitName,
    id: 'Unit Name',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.unitCount,
    id: 'Unit Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.unitPrice,
    id: 'Unit Price',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row.totalCost,
    id: 'Total',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',

    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => bitBToB(row.paymentCompleted),
    id: 'Payment Completed',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCheckboxCell({ props }),
  },
  {
    accessorFn: (row) => bitBToB(row.clientBillable),
    id: 'Client Billable',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCheckboxCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (row.incurredByDisplayNames ?? []).join(', ') ?? [],
    id: 'Incurred By',
    sortUndefined: 1,
    filterFn: 'arrIncludesSome',
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
];
