import Auth from '@aws-amplify/auth';
import { menuNavigation, useGetCurrentUser, usePathParts, usePermissions } from '@colosseum/data';
import {
  BreadcrumbsProps,
  GladiateLoader,
  Menu,
  PaymentPromptBanner,
  SlideoverContext,
} from '@colosseum/shared-ui';
import { PAGES_MAP } from '@gladiate/types';
import jwt_decode from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorPage from '../../error/ErrorPage';
import LoginPage from '../LoginPage';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useGetCurrentUser();
  const firmUser = currentUser.data?.data;
  const enabled = firmUser?.Enabled;
  const navigate = useNavigate();

  const { hasPageAccess } = usePermissions();

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const [params] = useSearchParams();
  const { resource, resourceId } = usePathParts();

  useEffect(() => {
    checkAuth();
  }, [currentUser.isError]);

  const signOut = async () => {
    try {
      await Auth.signOut().then(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        window.location.href = '/login';
      });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  const refreshToken = async () => {
    try {
      const data = await Auth.currentSession();

      localStorage.setItem('accessToken', data.getAccessToken().getJwtToken());
      localStorage.setItem('idToken', data.getIdToken().getJwtToken());
      localStorage.setItem('refreshToken', data.getRefreshToken().getToken());
    } catch (err) {
      console.error(err);
    }
  };

  const checkAuth = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('idToken');
      if (token) {
        const decoded: any = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime || currentUser.isError) {
          await refreshToken();
        }
      }
      const user = await Auth.currentAuthenticatedUser(); // Check if user is authenticated

      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoading(false);
      await signOut();
    }
  };

  const accessedSection = menuNavigation.find((item) => item.href === window.location.pathname)
    ?.pageMapKey as keyof typeof PAGES_MAP;

  useEffect(() => {
    if (!enabled && !currentUser.isLoading) {
      signOut();
    }
  }, [enabled]);

  useEffect(() => {
    if (!resource && !resourceId) return;
    let navigateToUrl = `/${resource}/${resourceId}`;
    if (!resourceId) {
      navigateToUrl = `/${resource}`;
    }
    const caseItemType = params.get('case-item-type');
    const caseItemId = params.get('case-item-id');
    const contactId = params.get('contact-id');

    if (contactId) {
      const url = `/contacts/${contactId}`;
      navigate(url, { replace: true });
    } else {
      navigate({
        pathname: navigateToUrl,
        search: params.toString(),
      });
      caseItemId &&
        setPendingSlideoverToOpen({
          type: caseItemType as BreadcrumbsProps['resourceType'],
          id: caseItemId,
        });
    }
  }, []);

  if (isLoading || currentUser.isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center">
          <GladiateLoader />
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  if (!hasPageAccess(accessedSection)) {
    return (
      <Menu>
        <ErrorPage
          code={403}
          errorTitle="Not Authorized"
          errorMessage="You are not authorized to view this page. Please contact your administrator."
        />
      </Menu>
    );
  }

  return (
    <>
      <PaymentPromptBanner />
      <Menu />
    </>
  );
};

export default PrivateRoute;
